/*
 * Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
 * and/or distributed without the express prior written permission of MindMaze Holding SA.
 */

import apiService from '../services/api.service'

const LOGGED_USER = JSON.parse(localStorage.getItem('user'))
const initialState = LOGGED_USER
    ? { status: { loggedIn: true }, LOGGED_USER }
    : { status: { loggedIn: false }, LOGGED_USER: null }

export const auth = {
    namespaced: true,

    state: initialState,

    getters: {
        loggedUser(state) {
            return state.LOGGED_USER
        },
        loggedIn(state) {
            return state.status.loggedIn
        },
        isIntentoUser(state) {
            return state.LOGGED_USER && state.LOGGED_USER.userType === 'IntentoUser'
        },
        isIntentoAdmin(state) {
            return state.LOGGED_USER && state.LOGGED_USER.roles.find(role => role === 'IntentoAdmin')
        },
        isIntentoSales(state) {
            return state.LOGGED_USER && state.LOGGED_USER.roles.find(role => role === 'IntentoSales')
        },
        isIntentoSoftware(state) {
            return state.LOGGED_USER && state.LOGGED_USER.roles.find(role => role === 'IntentoSoftware')
        },
        isIntentoDPO(state) {
            return state.LOGGED_USER && state.LOGGED_USER.roles.find(role => role === 'DPO')
        },
        isSiteUser(state) {
            return state.LOGGED_USER && state.LOGGED_USER.userType === 'SiteUser'
        },
        isSiteAdmin(state) {
            return state.LOGGED_USER && state.LOGGED_USER.roles.find(role => role === 'SiteAdmin')
        },
        isCustomerUser(state) {
            return state.LOGGED_USER && state.LOGGED_USER.userType === 'CustomerUser'
        },
        isCustomerAccountManager(state) {
            return state.LOGGED_USER && state.LOGGED_USER.roles.find(role => role === 'CustomerAccountManager')
        },
        customerIdOfAccountManager(state, getters) {
            if (getters.isCustomerAccountManager) {
                return state.LOGGED_USER.customerId;
            } else {
                return -1;
            }
        },
        computeHome(state, getters) {
            if (state.status.loggedIn) {
                if (getters.isIntentoUser) {
                    return '/admin'
                } else if (getters.isCustomerAccountManager) {
                    return '/customer-account/' + state.LOGGED_USER.customerAccountId + '/sites'
                } else {
                    // At the moment site admins are not allowed to do much
                    return '/error'
                }
            } else {
                return '/login'
            }
        }
    },

    actions: {
        login({ commit }, user) {
            return apiService.login(user).then(
                userResponse => {
                    commit('loginSuccess', userResponse)
                    return Promise.resolve(userResponse)
                },
                error => {
                    commit('loginFailure')
                    return Promise.reject(error)
                }
            )
        },
        logout({ commit }) {
            apiService.logout()
            commit('logout')
        }
    },

    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true
            state.LOGGED_USER = user
        },
        loginFailure(state) {
            state.status.loggedIn = false
            state.LOGGED_USER = null
        },
        logout(state) {
            state.status.loggedIn = false
            state.LOGGED_USER = null
        }
    }
}
