/*
 * Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
 * and/or distributed without the express prior written permission of MindMaze Holding SA.
 */

import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth
  }
})
