/*
 * Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
 * and/or distributed without the express prior written permission of MindMaze Holding SA.
 */

import 'bootstrap'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import VeeValidate from 'vee-validate'
import Vue from 'vue'
import Vuex from 'vuex'

import "@/assets/styles/sass/themes/intento.scss"

import { router } from './router'
import store from './store'

import App from './App.vue'

import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons'

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt)

Vue.config.productionTip = false

Vue.use(BootstrapVue)

Vue.use(IconsPlugin)

Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Vuex)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
