<!--
Copyright � MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <nav v-if="loggedUser" class="navbar navbar-expand">

        <div v-if="!loggedUser" class="navbar-nav ml-auto">
            <li class="nav-item">
                <router-link to="/login" class="nav-link">
                <font-awesome-icon icon="sign-in-alt" /> Login
                </router-link>
            </li>
        </div>

        <div v-if="loggedUser" class="navbar-nav ml-auto">
            <li class="nav-item">
                <router-link :to="'/user/' + loggedUser.userId" class="nav-link">
                <font-awesome-icon icon="user" /> My Profile
                </router-link>
            </li>
            <li class="nav-item">
                <a class="nav-link" href @click.prevent="logOut">
                <font-awesome-icon icon="sign-out-alt" /> LogOut
                </a>
            </li>
        </div>
    </nav>
</template>

<script>
    
import { mapGetters } from 'vuex';

export default {
    name : 'TopNavigation',
    computed: {
        ...mapGetters('auth', ['computeHome', 'loggedUser'])
    },
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        }
    }
};
</script>

<style scoped>
</style>