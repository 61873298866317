<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
 
    <div id="app">

        <top-navigation></top-navigation>

        <div>

            <b-row>

                <b-col cols="2">
                    <side-navigation></side-navigation>
                </b-col>

                <b-col cols="10">
                    <router-view />

                    <div class="text-center" style="font-size: 80%">
                        Intento® Cloud Platform - v1.6.0
                    </div>
                </b-col>

            </b-row>

        </div>

    </div>

</template>

<script>

    import { mapGetters } from 'vuex';
    import TopNavigation from './views/TopNavigation'
    import SideNavigation from './views/SideNavigation'

    export default {
        components: {
            TopNavigation, SideNavigation
        }, computed: {
            ...mapGetters('auth', ['loggedIn'])
        }
    };

</script>

<style lang="" scoped>
</style>
