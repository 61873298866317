<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-container">
                <img id="logo-img" alt="logo"
                     src="@/assets/images/logo-big.png" />
                <form name="form" @submit.prevent="handleLogin">
                    <div class="form-group">
                        <label for="username">Username</label>
                        <input v-model="user.username"
                               v-validate="'required'"
                               type="text"
                               class="form-control"
                               name="username" />
                        <div v-if="errors.has('username')"
                             class="alert alert-danger"
                             role="alert">Username is required!</div>
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input v-model="user.password"
                               v-validate="'required'"
                               type="password"
                               class="form-control"
                               name="password" />
                        <div v-if="errors.has('password')"
                             class="alert alert-danger"
                             role="alert">Password is required!</div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary btn-block" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Login</span>
                        </button>
                    </div>
                    <div class="text-right" style="font-size: 80%">
                      <router-link to="/login/recover-password" >
                        Forgot password?
                      </router-link>
                    </div>
                    <div class="form-group">
                        <div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

class User {
  constructor(username, email, password) {
    this.username=username;
    this.email=email;
    this.password=password;
  }
}

export default {
  name: 'Login',
  data() {
    return {
      user: new User('', '', ''),
      loading: false,
      message: ''
    };
  },
  computed: {
    ...mapGetters('auth',['computeHome', 'loggedIn'])
  },
  created() {
    if (this.loggedIn) {
      this.$router.push(this.computeHome);
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push(this.computeHome);
            },
            error => {
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    }
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: auto;
    margin-top: 8%;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.logo-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.form-group {
    margin-top: 20px;
}
</style>