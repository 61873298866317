<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>

    <div class="sidenav" v-if="isLoggedAndIntentoUser()">

        <b-list-group>

            <b-list-group-item>
                <router-link tag="a" :to="computeHome">
                    <span class="sidenav-item">Home</span>
                </router-link>
            </b-list-group-item>

            <b-list-group-item v-if="isIntentoAdmin">
                <router-link tag="a" :to="{ name: 'userDashboard' }">
                    <span class="sidenav-item">Users Management</span>
                </router-link>
            </b-list-group-item>

            <b-list-group-item v-if="isIntentoSales">
                <router-link tag="a" :to="{ name: 'customerDashboard' }">
                    <span class="sidenav-item">Customer Management</span>
                </router-link>
            </b-list-group-item>

            <b-list-group-item v-if="isIntentoSales">
                <router-link tag="a" :to="{ name: 'inventory' }">
                    <span class="sidenav-item">Inventory Management</span>
                </router-link>
            </b-list-group-item>

            <b-list-group-item v-if="isIntentoSoftware">
                <router-link tag="a" :to="{ name: 'softwareDashboard' }">
                    <span class="sidenav-item">Software Management</span>
                </router-link>
            </b-list-group-item>

            <b-list-group-item v-if="isIntentoDPO">
                <router-link tag="a" :to="{ name: 'dpoDashboard' }">
                    <span class="sidenav-item">DPO Management</span>
                </router-link>
            </b-list-group-item>

        </b-list-group>

    </div>

</template>

<script>

    import { mapGetters } from 'vuex';

    export default {
        name: 'SideNavigation',
        computed: {
            ...mapGetters('auth', ['computeHome', 'isIntentoAdmin', 'isIntentoUser', 'loggedIn', 'isCustomerAccountManager', 'isIntentoSales', 'isIntentoSoftware', 'isIntentoDPO'])
        },
        methods: {
            isLoggedAndIntentoUser() {
                return this.loggedIn && this.isIntentoUser;
            }
        }
    };

</script>

<style scoped>

.sidenav {
    margin-top: 20px;
}

.sidenav-item {
    margin: 10px;
    display: block;
}

</style>