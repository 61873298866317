/*
 * Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
 * and/or distributed without the express prior written permission of MindMaze Holding SA.
 */

import axios from "axios";

import { router } from "../router";

import store from "../store";

const API_URL = 'https://stg.intento.mm-companion.com/api/'

// const API_URL = 'http://localhost:5000/api/'

class ApiService {
    constructor() {
        // Add a request interceptor
        axios.interceptors.request.use(
            (config) => {
                const user = JSON.parse(localStorage.getItem("user"));
                if (user && user.token) {
                    config.headers["Authorization"] = "Bearer " + user.token;
                }
                config.headers["Content-Type"] = "application/json";
                return config;
            },
            (error) => {
                Promise.reject(error);
            }
        );

        // Add a response interceptor
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            function(error) {
                const originalRequest = error.config;

                //If the authentication error is in Login or refresh token, go to Login user
                if ((error.response.status === 401 && originalRequest.url === API_URL + "login") || originalRequest.url === API_URL + "token/refresh") {
                    store.dispatch("auth/logout");
                    router.push({
                        path: "/login",
                    });

                    return Promise.reject(error);
                }

                // If is another request and we are not already retrying to login
                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const savedUser = JSON.parse(localStorage.getItem("user"));

                    // Request tokens refresh
                    return axios
                        .post(API_URL + "token/refresh", {
                            accessToken: savedUser.token,
                            refreshToken: savedUser.refreshToken,
                        })
                        .then((response) => {
                            if (response.data.accessToken && response.data.refreshToken) {
                                savedUser.token = response.data.accessToken;
                                savedUser.refreshToken = response.data.refreshToken;
                                localStorage.setItem("user", JSON.stringify(savedUser));
                                axios.defaults.headers.common["Authorization"] = "Bearer " + savedUser.token;
                                return axios(originalRequest);
                            }
                        });
                }
                return Promise.reject(error);
            }
        );
    }

    //-----------------------------------------------------------------------
    // Authentication
    //-----------------------------------------------------------------------

    login(user) {
        return axios
            .post(API_URL + "login", {
                username: user.username,
                password: user.password,
            })
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem("user");
    }

    recoverPassword(loginEmail) {
        return axios
            .post(API_URL + "login/recover-password", {
                email: loginEmail
            })
            .then((response) => {
                return response.data;
            });
    }

    resetPassword(token, password) {
        return axios
            .post(API_URL + "login/reset-password", {
                token: token,
                password: password,
            })
            .then((response) => {
                return response.data;
            });
    }
    //-----------------------------------------------------------------------
    // Users Management
    //-----------------------------------------------------------------------

    getUser(id) {
        return axios.get(API_URL + `user/${id}`);
    }

    addUser(data) {
        return axios.post(API_URL + "user", data);
    }

    updateUser(id, data) {
        return axios.put(API_URL + `user/${id}`, data);
    }

    deleteUser(id) {
        return axios.delete(API_URL + `user/${id}`);
    }

    //-----------------------------------------------------------------------
    // Users Management - Intento Users
    //-----------------------------------------------------------------------

    getIntentoUsers() {
        return axios.get(API_URL + "user?userType=IntentoUser").then((response) => {
            return response.data;
        });
    }

    //-----------------------------------------------------------------------
    // Users Management - Customer Account Users
    //-----------------------------------------------------------------------

    getCustomerUsers(customerId) {
        return axios.get(API_URL + `customer-account/${customerId}/users`).then((response) => {
            return response.data;
        });
    }

    //-----------------------------------------------------------------------
    // Customers
    //-----------------------------------------------------------------------

    getCustomerAccounts() {
        return axios.get(API_URL + "customer-account").then((response) => {
            return response.data;
        });
    }

    getCustomerAccount(id) {
        return axios.get(API_URL + `customer-account/${id}`).then((response) => {
            return response.data;
        });
    }

    addCustomerAccount(data) {
        return axios.post(API_URL + "customer-account", data);
    }

    updateCustomerAccount(id, data) {
        return axios.put(API_URL + `customer-account/${id}`, data);
    }

    deleteCustomerAccount(id) {
        return axios.delete(API_URL + `customer-account/${id}`);
    }

    getCustomerSites(customerId) {
        return axios.get(API_URL + `customer-account/${customerId}/sites`).then((response) => {
            return response.data;
        });
    }

    //-----------------------------------------------------------------------
    // Sites
    //-----------------------------------------------------------------------

    getSites() {
        return axios.get(API_URL + "site").then((response) => {
            return response.data;
        });
    }

    getSite(id) {
        return axios.get(API_URL + `site/${id}`).then((response) => {
            return response.data;
        });
    }

    addSite(data) {
        return axios.post(API_URL + "site", data);
    }

    updateSite(id, data) {
        return axios.put(API_URL + `site/${id}`, data);
    }

    deleteSite(id) {
        return axios.delete(API_URL + `site/${id}`);
    }

    getSiteLicenses(siteId) {
        return axios.get(API_URL + `site/${siteId}/licenses`).then((response) => {
            return response.data;
        });
    }

    getSiteIntentoProDevices(siteId) {
        return axios.get(API_URL + `site/${siteId}/intento-pro-devices`).then((response) => {
            return response.data;
        });
    }

    getSiteTablets(siteId) {
        return axios.get(API_URL + `site/${siteId}/tablet-devices`).then((response) => {
            return response.data;
        });
    }

    getSiteUsageStatistics(siteId, startDate, endDate) {
        return axios.get(API_URL + `site/${siteId}/usage?startDate=${startDate}&endDate=${endDate}`).then((response) => {
            return response.data;
        });
    }

    getSiteUsers(siteId) {
        return axios.get(API_URL + `site/${siteId}/users`).then((response) => {
            return response.data;
        });
    }

    //-----------------------------------------------------------------------
    // Licenses
    //-----------------------------------------------------------------------

    getLicenses() {
        return axios.get(API_URL + "license").then((response) => {
            return response.data;
        });
    }

    getLicense(id) {
        return axios.get(API_URL + `license/${id}`).then((response) => {
            return response.data;
        });
    }

    addLicense(data) {
        return axios.post(API_URL + "license", data);
    }

    updateLicense(id, data) {
        return axios.put(API_URL + `license/${id}`, data);
    }

    deleteLicense(id) {
        return axios.delete(API_URL + `license/${id}`);
    }

    getLicenseUsageStatistics(licenseId, startDate, endDate) {
        return axios.get(API_URL + `license/${licenseId}/usage?startDate=${startDate}&endDate=${endDate}`).then((response) => {
            return response.data;
        });
    }

    //-----------------------------------------------------------------------
    // Stimulation Sessions
    //-----------------------------------------------------------------------

    getLicenseStimulationSessions(licenseId, startDate, endDate) {
        return axios.get(API_URL + `license/${licenseId}/stimulation-session?startDate=${startDate}&endDate=${endDate}`).then((response) => {
            return response.data;
        });
    }

    getStimulationSession(sessionId) {
        return axios.get(API_URL + `stimulation-session/${sessionId}`).then((response) => {
            return response.data;
        });
    }

    getStimulationSessionIntervals(sessionId) {
        return axios.get(API_URL + `stimulation-session/${sessionId}/intervals`).then((response) => {
            return response.data;
        });
    }

    //-----------------------------------------------------------------------
    // Firmware and tablet software management
    //-----------------------------------------------------------------------

    getFirmwareUpdates() {
        return axios.get(API_URL + `update/firmware`).then((response) => {
            return response.data;
        });
    }

    getFirmwareUpdateFile(id) {
        return axios({
            url: API_URL + `update/download-firmware-file/${id}`,
            method: "GET",
            responseType: "blob",
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "firmware.zip");
            document.body.appendChild(link);
            link.click();
        });
    }

    getFirmwareUpdateMetadata(id) {
        return axios.get(API_URL + `update/firmware/${id}`).then((response) => {
            return response.data;
        });
    }

    addFirmwareUpdate(data) {
        var formData = new FormData();
        formData.append("file", data);
        return axios.post(API_URL + "update/firmware/upload", formData, { "Content-Type": "multipart/form-data" });
    }

    updateFirmwareUpdateMetadata(id, data) {
        return axios.put(API_URL + `update/firmware/${id}`, data);
    }

    getTabletUpdates() {
        return axios.get(API_URL + `update/app`).then((response) => {
            return response.data;
        });
    }

    getTabletUpdateMetadata(id) {
        return axios.get(API_URL + `update/app/${id}`).then((response) => {
            return response.data;
        });
    }

    addTabletUpdateMetadata(data) {
        return axios.post(API_URL + `update/app`, data);
    }

    updateTabletUpdateMetadata(id, data) {
        return axios.put(API_URL + `update/app/${id}`, data);
    }

    //------------------------
    // Ifu management
    //------------------------

    getIfuInformationByAppVersionId(appVersionId) {
        return axios.get(API_URL + `ifu/?appVersionId=${appVersionId}`).then((response) => {
            return response.data;
        });
    }

    uploadIfuFile(appVersionId, languageCode, data) {
        var formData = new FormData();
        formData.append("file", data);
        return axios.post(API_URL + `ifu?appVersionId=${appVersionId}&language=${languageCode}`, formData, { "Content-Type": "multipart/form-data" });
    }

    getIfuFile(fileId, fileName) {
        return axios({
            url: API_URL + `ifu/download/${fileId}`,
            method: "GET",
            responseType: "blob",
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        });
    }

    //------------------------
    // Searches
    //------------------------
    searchLicense(code) {
        return axios.get(API_URL + `search/license/${code}`).then((response) => {
            return response.data;
        });
    }

    searchTabletDevice(serialNumber) {
        return axios.get(API_URL + `search/tablet-device/${serialNumber}`).then((response) => {
            return response.data;
        });
    }

    searchDevice(serialNumber) {
        return axios.get(API_URL + `search/ipro-device/${serialNumber}`).then((response) => {
            return response.data;
        });
    }

    //------------------------
    // Inventory
    //------------------------

    getIntentoProDevices() {
        return axios.get(API_URL + `inventory/intento-pro`).then((response) => {
            return response.data;
        });
    }

    saveDeviceInformation(deviceId, device) {
        return axios.put(API_URL + `inventory/intento-pro/${deviceId}`, device);
    }

    //------------------------
    // Policies
    //------------------------

    getPolicies() {
        return axios.get(API_URL + `privacy-policy`).then((response) => {
            return response.data;
        });
    }

    addPolicy(data) {
        return axios.post(API_URL + `privacy-policy`, data);
    }

    updatePolicy(id, data) {
        return axios.put(API_URL + `privacy-policy/${id}`, data);
    }

     //------------------------
    // EULA
    //------------------------

    getEulaList() {
        return axios.get(API_URL + `eula`).then((response) => {
            return response.data;
        });
    }

    addEula(data) {
        return axios.post(API_URL + `eula`, data);
    }

    updateEula(id, data) {
        return axios.put(API_URL + `eula/${id}`, data);
    }
}

export default new ApiService();
