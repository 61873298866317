/*
 * Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
 * and/or distributed without the express prior written permission of MindMaze Holding SA.
 */

import Router from 'vue-router';
import Vue from 'vue';

import Login from './views/Login.vue';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'login',
            component: Login
        },
        {
            path: '/login',
            component: Login
        },
        {
            name: 'user',
            path: '/user/:userId',
            props: true,
            // lazy-loaded
            component: () => import('./views/UserProfile.vue'),
            meta: {
                breadCrumb: 'My Profile'
            }
        },
        {
            name: 'customer',
            path: '/customer-account/:customerId',
            props: true,
            children: [
                {
                    name: 'customerSites',
                    path: 'sites',
                    component: () => import('./views/CustomerAccountSites.vue')
                },
                {
                    name: 'customerAccountManagers',
                    path: 'managers',
                    component: () => import('./views/CustomerAccountManagers.vue')
                }
            ],
            // lazy-loaded
            component: () => import('./views/CustomerAccount.vue'),
            meta: {
                breadCrumb: 'Customer'
            }
        },
        {
            name: 'site',
            path: '/customer-account/:customerId/site/:siteId',
            props: true,
            children: [
                {
                    name: 'siteStatistics',
                    path: 'statistics',
                    props: true,
                    component: () => import('./components/ExerciseStatistics.vue')
                },
                {
                    name: 'siteLicenses',
                    path: 'licenses',
                    component: () => import('./views/SiteLicenses.vue')
                },
                {
                    name: 'siteUsers',
                    path: 'site-users',
                    component: () => import('./views/SiteUsers.vue')
                }, 
                {
                    name: 'siteTablets',
                    path: 'tablets',
                    component: () => import('./views/SiteTablets.vue')
                },
                {
                    name: 'siteDevices',
                    path: 'devices',
                    component: () => import('./views/SiteDevices.vue')
                }
            ],
            // lazy-loaded
            component: () => import('./views/Site.vue'),
            meta: {
                breadCrumb: 'Site'
            }
        },
        {
            name: 'customerDashboard',
            path: '/admin/customer-accounts',
            // lazy-loaded
            component: () => import('./views/CustomerAccounts.vue'),
            meta: {
                breadCrumb: 'Customer Management'
            }
        },
        {
            name: 'intentoDashboard',
            path: '/admin',
            // lazy-loaded
            component: () => import('./views/IntentoDashboard.vue'),
            meta: {
                breadcrumb: 'Admin Home'
            }
        },
        {
            name: 'userDashboard',
            path: '/admin/users',
            // lazy-loaded
            component: () => import('./views/IntentoUsers.vue'),
            meta: {
                breadcrumb: 'User Management'
            }
        },
        {
            name: 'softwareDashboard',
            path: '/admin/software',
            // lazy-loaded
            component: () => import('./views/SoftwareDashboard.vue'),
            meta: {
                breadCrumb: 'Software Management'
            }
        },
        {
            name: 'dpoDashboard',
            path: '/admin/dpo',
            // lazy-loaded
            component: () => import('./views/DpoDashboard.vue'),
            meta: {
                breadCrumb: 'DPO Management'
            }
        },
        {
            name: 'license',
            path: '/customer-account/:customerId/site/:siteId/license/:licenseId',
            props: true,
            children: [],
            component: () => import('./views/License.vue')
        },
        {
            name: 'stimulationSession',
            path: '/customer-account/:customerId/site/:siteId/license/:licenseId/stimulation-session/:stimulationSessionId',
            props: true,
            // lazy-loaded
            component: () => import('./views/StimulationSession.vue')
        },
        {
            name: 'inventory',
            path: '/inventory',
            props: true,
            // lazy-loaded
            component: () => import('./views/Inventory.vue')
        },
        {
            name: 'recoverPassword',
            path: '/login/recover-password',
            // lazy-loaded
            component: () => import('./views/RecoverPassword.vue')
        },
        {
            name: 'changePassword',
            path: '/login/change-password',
            // lazy-loaded
            component: () => import('./views/ChangePassword.vue')
        }
    ]
});